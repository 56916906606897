/* You can add global styles to this file, and also import other style files */

@font-face {
  font-family: 'Effra';
  src: url('assets/fonts/Effra-Italic.eot');
  src: url('assets/fonts/Effra-Italic.eot?#iefix') format('embedded-opentype'),
      url('assets/fonts/Effra-Italic.woff2') format('woff2'),
      url('assets/fonts/Effra-Italic.woff') format('woff'),
      url('assets/fonts/Effra-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Effra';
  src: url('assets/fonts/Effra-BoldItalic.eot');
  src: url('assets/fonts/Effra-BoldItalic.eot?#iefix') format('embedded-opentype'),
      url('assets/fonts/Effra-BoldItalic.woff2') format('woff2'),
      url('assets/fonts/Effra-BoldItalic.woff') format('woff'),
      url('assets/fonts/Effra-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Effra';
  src: url('assets/fonts/Effra-Regular.eot');
  src: url('assets/fonts/Effra-Regular.eot?#iefix') format('embedded-opentype'),
      url('assets/fonts/Effra-Regular.woff2') format('woff2'),
      url('assets/fonts/Effra-Regular.woff') format('woff'),
      url('assets/fonts/Effra-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Effra';
  src: url('assets/fonts/Effra-Bold.eot');
  src: url('assets/fonts/Effra-Bold.eot?#iefix') format('embedded-opentype'),
      url('assets/fonts/Effra-Bold.woff2') format('woff2'),
      url('assets/fonts/Effra-Bold.woff') format('woff'),
      url('assets/fonts/Effra-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

html, body { height: 100%; }
body {
  overflow:overlay;
  margin: 0;
  font-family: 'Effra', "Helvetica Neue", sans-serif;
  font-size: 14px;
  line-height: 16px;

  p {
    margin: 10px 0;
  }

  h3 {
    font-size: 20px;
    line-height: 22px;
  }

  h4 {
    font-size: 16px;
  }

  a, a:active, a:visited {
    color: #fff;
  }

  button.btn {
    width: 100%;
    text-transform: uppercase;
    height: 48px;
    letter-spacing: 0.04em;
    font-feature-settings: 'ordn' on, 'calt' off;
    line-height: 16px !important;
    span {
      white-space: normal;
    }
  }

  button:disabled {
    background-color: #d6d6d6 !important;
    color: #fff !important;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0,0,0,0);
  white-space: nowrap;
  border: 0;
}
